import { AppProvider } from './contexts/app.context';
import { Main } from './pages/main.page';

function App() {
  return (
    <AppProvider>
        <Main></Main>
    </AppProvider>
  );
}

export default App;
