import { Box, Text, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export const StripePricingTable: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  return (
    <Box py={12} bg={"secondary.500"}>

      <VStack align="center">
        {/* Elegant text section */}
        <Box
          textAlign="center"
          mb={8}
        >
          <Text
            fontSize={{ base: "xl", md: "2xl" }}
            fontWeight="medium"
            letterSpacing="tight"
            _hover={{
              transform: "translateY(-3px)",
              transition: "all 0.2s ease"
            }}
          >
            Scegli il tuo piano, ricevi le credenziali e scarica l'app
          </Text>
        </Box>
      </VStack>

      <stripe-pricing-table
        pricing-table-id="prctbl_1QT09YQkEDVQUEGXDxc14HCQ"
        publishable-key="pk_live_51QSKfZQkEDVQUEGXMQVJKlog1NcgJECy8kHAMTWOyhomdQYZ2aere0heYU7LPUpJfbywFfRXUm9grz71VNct9Rzg00m6JglKCT"
        client-reference-id="web-portal-public"
      />
    </Box>
  );
};