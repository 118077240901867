import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';

export const NotFoundPage: React.FC = () => {
  return (
    <Flex direction={'column'}>
      <Box p={'80'} bg={'Background'} textAlign={'center'}>
        <Heading size="5xl">404</Heading>
        <Text textStyle={'xl'}>Pagina non trovata.</Text>
      </Box>
    </Flex>
  );
}
