import { Box } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from './footer.layout';
import { Navbar } from './navbar.layout';

export const PublicLayout: React.FC = () => {

  return (
    <Box bg={"background.500"}>
      <Navbar />
      <Outlet />
      <Footer />
    </Box>
  );

}