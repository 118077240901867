import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PoliciesLayout } from '../layouts/policies.layout';
import { PublicLayout } from '../layouts/public.layout';
import { HomePage } from './home/home.page';
import { SubscriptionTest } from './payments/subscription-test.page';
import { Subscription } from './payments/subscription.page';
import { SuccessPage } from './payments/success.page';
import { PolicyEightPage } from './policies/policy-eight.page';
import { PolicyFivePage } from './policies/policy-five.page';
import { PolicyFourPage } from './policies/policy-four.page';
import { PolicySevenPage } from './policies/policy-seven.page';
import { PolicySixPage } from './policies/policy-six.page';
import { PolicyThreePage } from './policies/policy-three.page';
import { PolicyTwoPage } from './policies/policy-two.page';
import { NotFoundPage } from './utilities/not-found.page';
import { PolicyPediatricianInformationPersonalDataPage } from './policies/pediatricians/information-personal-data-pediatrician.page';

export const Main: React.FC = () => {

  return (    
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="payment/subscriptionTest" element={<SubscriptionTest/>} />
          <Route path="payment/subscription" element={<Subscription />} />
          <Route path="payment/subscription/success" element={<SuccessPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route path="policies" element={<PoliciesLayout />}>
          <Route path="pediatrician/information-personal-data" element={<PolicyPediatricianInformationPersonalDataPage />} />
          <Route path="two" element={<PolicyTwoPage />} />
          <Route path="four" element={<PolicyFourPage />} />
          <Route path="five" element={<PolicyFivePage />} />
          <Route path="six" element={<PolicySixPage />} />
          <Route path="seven" element={<PolicySevenPage />} />
          <Route path="eight" element={<PolicyEightPage />} />
          <Route path="three" element={<PolicyThreePage/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );

}