import { createSystem, defaultConfig, defineConfig } from "@chakra-ui/react";

const customConfig = defineConfig({
  strictTokens: true,
  theme: {
    tokens: {
      colors: {
        primary: {
          50: { value: "#e8f2f1" },
          100: { value: "#b9d5d4" },
          200: { value: "#97c1bf" },
          300: { value: "#68a5a2" },
          400: { value: "#4a9390" },
          500: { value: "#1d7874" },
          600: { value: "#1a6d6a" },
          700: { value: "#155552" },
          800: { value: "#104240" },
          900: { value: "#0c3231" },
        },
        secondary: {
          50: { value: "#edf7f7" },
          100: { value: "#c7e7e6" },
          200: { value: "#acdbda" },
          300: { value: "#86cbc9" },
          400: { value: "#6ec1bf" },
          500: { value: "#4ab1af" },
          600: { value: "#43a19f" },
          700: { value: "#357e7c" },
          800: { value: "#296160 " },
          900: { value: "#1f4a4a" },
        },
        tertiary: {
          50: { value: "#fff9e6" },
          100: { value: "#ffebb0" },
          200: { value: "#ffe28a" },
          300: { value: "#ffd454" },
          400: { value: "#ffcc33" },
          500: { value: "#ffbf00" },
          600: { value: "#e8ae00" },
          700: { value: "#b58800" },
          800: { value: "#8c6900" },
          900: { value: "#6b5000" },
        },
        background: {
          50: { value: "#ffffff" },
          100: { value: "#fdfdfd" },
          200: { value: "#fdfdfd" },
          300: { value: "#fcfcfc" },
          400: { value: "#fbfbfb" },
          500: { value: "#fafafa" },
          600: { value: "#e4e4e4" },
          700: { value: "#b2b2b2" },
          800: { value: "#8a8a8a" },
          900: { value: "#696969" },
        },
        success: {
          50: { value: '#F0FDF4' },
          100: { value: '#DCFCE7' },
          200: { value: '#BBF7D0' },
          300: { value: '#86EFAC' },
          400: { value: '#4ADE80' },
          500: { value: '#22C55E' },  // default
          600: { value: '#16A34A' },
          700: { value: '#15803D' },
          800: { value: '#166534' },
          900: { value: '#14532D' },
        },
        warning: {
          50: { value: '#FFFBEB' },
          100: { value: '#FEF3C7' },
          200: { value: '#FDE68A' },
          300: { value: '#FCD34D' },
          400: { value: '#FBBF24' },
          500: { value: '#F59E0B' },  // default
          600: { value: '#D97706' },
          700: { value: '#B45309' },
          800: { value: '#92400E' },
          900: { value: '#78350F' },
        },
        error: {
          50: { value: '#feeceb' },
          100: { value: '#fac5c1' },
          200: { value: '#f8a9a3' },
          300: { value: '#f5827a' },
          400: { value: '#f36960' },
          500: { value: '#f04438' },  // default
          600: { value: '#da3e33' },
          700: { value: '#aa3028' },
          800: { value: '#84251f' },
          900: { value: '#651d18' },
        },
        danger: {
          50: { value: "#FDE9E7" },
          100: { value: "#FAC1BD" },
          200: { value: "#F79892" },
          300: { value: "#F47067" },
          400: { value: "#F0483D" },
          500: { value: "#ED2012" },
          600: { value: "#BE1A0E" },
          700: { value: "#8E130B" },
          800: { value: "#5F0D07" },
          900: { value: "#2F0604" }
        },
      }
    },
    semanticTokens: {
      colors: {
        primary: {
          solid: { value: "{colors.primary.500}" },
          contrast: { value: "{colors.primary.100}" },
          fg: { value: "{colors.primary.700}" },
          muted: { value: "{colors.primary.400}" },
          subtle: { value: "{colors.primary.200}" },
          emphasized: { value: "{colors.primary.300}" },
          focusRing: { value: "{colors.primary.500}" },
        },
        danger: {
          solid: { value: "{colors.danger.500}" },
          contrast: { value: "{colors.danger.100}" },
          fg: { value: "{colors.danger.700}" },
          muted: { value: "{colors.danger.400}" },
          subtle: { value: "{colors.danger.200}" },
          emphasized: { value: "{colors.danger.300}" },
          focusRing: { value: "{colors.danger.500}" },
        },
      },
    },
  },
})




// util https://www.tints.dev/brand/828DEE

//const config = mergeConfigs(defaultConfig, myconfig);
//const system = createSystem(config);
const KiddocareTheme = createSystem(defaultConfig, customConfig);
export default KiddocareTheme;