import { ChakraProvider, Theme } from "@chakra-ui/react";
import { ThemeProvider } from "next-themes";
import React, { createContext, ReactNode, useContext, useState } from "react";
import KiddocareTheme from "../constants/theme.constant";

// Definisci il tipo del contesto
interface AppContextType {
  IsDarkMode: boolean;
  SetIsDarkMode: (isDarkMode: boolean) => void;
}

// Crea il contesto con il tipo e un valore iniziale
const AppContext = createContext<AppContextType | undefined>(undefined);

// Provider del contesto
export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const value: AppContextType = {
    IsDarkMode: isDarkMode,
    SetIsDarkMode: setIsDarkMode,
  };
  
  return (
    <AppContext.Provider value={value}>
      <ChakraProvider value={KiddocareTheme}>
        <ThemeProvider attribute={'class'} disableTransitionOnChange>
          <Theme appearance={'light'}>
            {children}
          </Theme>
        </ThemeProvider>
      </ChakraProvider>
    </AppContext.Provider>
  );
};

// Hook per accedere al contesto in modo più semplice
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
