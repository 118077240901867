import { Box, Container, Image, Link, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

export const Footer: React.FC = () => {
  return (
    <Box bg={'gray.200'}>
      <Container as={Stack} py={'40'}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} gap={8}>
          {/* Logo & Description */}
          <Stack gap={6}>
            <Box>
              <Image src={'/logos/kiddocare-full.png'} alt="Kiddocare" height="40px" />
            </Box>
            <Text fontSize={'sm'}>
              Prenota visite pediatriche online e consulta il tuo pediatra direttamente dal tuo smartphone
            </Text>
          </Stack>

          {/* Company Links */}
          <Stack align={'flex-start'}>
            <Text fontWeight={'500'} fontSize={'lg'} mb={2}>Azienda</Text>
            <Link href={'#'}>Chi Siamo</Link>
            <Link href={'#'}>Contatti</Link>
            <Link href={'#'}>Lavora con noi</Link>
          </Stack>

          {/* Legal Links */}
          <Stack align={'flex-start'}>
            <Text fontWeight={'500'} fontSize={'lg'} mb={2}>Legale</Text>
            <Link href={'#'}>Privacy Policy</Link>
            <Link href={'#'}>Termini di Servizio</Link>
            <Link href={'#'}>Cookie Policy</Link>
          </Stack>

          {/* Social Links */}
          <Stack align={'flex-start'}>
            <Text fontWeight={'500'} fontSize={'lg'} mb={2}>Social</Text>
            <Stack direction={'row'} gap={6}>
              <Link href={'https://www.facebook.com/kiddocare'}>
                <FaFacebook size={26} />
              </Link>
              <Link href={'https://www.instagram.com/kiddocare/'}>
                <FaInstagram size={26} />
              </Link>
              <Link href={'https://www.linkedin.com/company/kiddocare'}>
                <FaLinkedin size={26} />
              </Link>
            </Stack>
          </Stack>
        </SimpleGrid>
        <Text pt={6} fontSize={'sm'} textAlign={'center'}>
          © {new Date().getFullYear()} Kiddocare. Tutti i diritti riservati
        </Text>
        <Text pb={6} fontSize={'sm'} textAlign={'center'}>
          AIHEALTH S.R.L. - VIA SANTA SOFIA 24, 20122 MILANO (MI) - P.IVA. 13640270966
        </Text>
      </Container>
    </Box>
  );
};