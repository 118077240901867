import { Box, Container, Flex, Image, Link, Stack, useDisclosure } from '@chakra-ui/react';
import React from 'react';

interface NavItem {
  label: string;
  href: string;
}

const NAV_ITEMS: NavItem[] = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Servizi',
    href: '/services',
  },
  {
    label: 'Contatti',
    href: '/contact',
  },
];

export const Navbar: React.FC = () => {
  const { open } = useDisclosure();

  return (
    <Box as="nav" bg="transparent" px={4} boxShadow="sm" borderBottom={'gray.100'} borderWidth={1}>
      <Container padding={'4'}>
        <Flex direction={'row'} verticalAlign={'middle'}>
        
          <Image src={'/logos/kiddocare-full.png'} height={"40px"} alt="Kiddocare Logo" />
          <Box flex={1} />
          {/*
          <HStack gap={8} display={{ base: 'none', md: 'flex' }} flex={2} justify="center" >
            {NAV_ITEMS.map((navItem) => (
              <Link key={navItem.label} href={navItem.href} fontSize="sm" fontWeight="500" >
                {navItem.label}
              </Link>
            ))}
          </HStack>

          <IconButton
            style={{ borderWidth: 10 }}
            display={{ base: 'flex', md: 'none' }}
            onClick={onToggle}
            variant="ghost"
            aria-label="Toggle Navigation"
          > {open ? <IoMdClose /> : <RxHamburgerMenu />} </IconButton>
           */}
        </Flex>
      </Container>

      {/* Mobile Navigation Menu */}
      {open && (
        <Box pb={4} display={{ base: 'block', md: 'none' }}>
          <Stack as="nav" gap={4}>
            {NAV_ITEMS.map((navItem) => (
              <Link
                key={navItem.label}
                href={navItem.href}
                fontSize="sm"
                fontWeight="500"
                color="gray.600"
                _hover={{
                  textDecoration: 'none',
                  color: 'blue.500',
                }}
              >
                {navItem.label}
              </Link>
            ))}
          </Stack>
        </Box>
      )}
    </Box>
  );
};