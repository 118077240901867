import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const PoliciesLayout: React.FC = () => {

  return (
    <Flex bg={"background.500"} direction={'column'} height={"100vh"} overflow={"auto"}>
      <Outlet />
    </Flex>
  );

}