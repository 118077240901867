import { Box, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { StripePricingTable } from '../../components/form/stripe-pricing-table.form';


export const Subscription: React.FC = () => {
  return (
    <Box>
      <Container>
        <Flex direction={{ base: 'column', md: 'row' }} p={10} gap={4}>
          <Flex direction="column" w={{ base: '100%', md: '55%' }} position="relative" h="500px" order={{ base: 2, md: 1 }}>
            {/* <Image src={backgroundWaves} alt="Background waves" objectFit="cover" w="100%" h="100%" position="absolute" top={0} left={0} transform="scale(0.7)" transformOrigin="center" /> */}
            <Image src={'/images/app-screen.images.png'} alt="App screen" objectFit="contain" maxW="90%" h="80%" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" zIndex={1} />
          </Flex>
          <Box w={{ base: '100%', md: '45%' }} order={{ base: 1, md: 2 }} p={{ base: 0, md: 20 }}>
            <Heading size="4xl" fontWeight="bold">Il tuo pediatra ti ha invitato su Kiddocare</Heading>
            <Text mt={4} textStyle="sm" fontWeight="bold">Con Kiddocare potrai parlare con il tuo pediatra</Text>
          </Box>
        </Flex>
      </Container>

      <StripePricingTable />
      
    </Box>
  );
}

