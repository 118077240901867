import React from 'react';
import { Badge, Box, Container, Flex, Heading, HStack, Link, Text, VStack } from '@chakra-ui/react';
// import { ReactComponent as AppleStoreIcon } from '../../assets/logos/apple.logo.svg';
// import { ReactComponent as PlayStoreIcon } from '../../assets/logos/play-store.logo.svg';
import { FaCheckCircle } from "react-icons/fa";
import { GrAppleAppStore } from "react-icons/gr";
import { FaGooglePlay } from "react-icons/fa6";

export const SuccessPage: React.FC = () => {
  return (
    <Box minH="100vh" py={12}>
      <Container maxW="container.md">
        <Box p={6} bg="blue.50" rounded="lg" width="full" borderWidth="1px" borderColor="blue.100" transition="all 0.2s" _hover={{ transform: 'translateY(-2px)', shadow: 'md' }}>
          <VStack gap={8} align="center">
            {/* Success Icon and Header */}
            <Flex direction="column" align="center" gap={4}>
              <FaCheckCircle size={50} color="forestgreen" />
              {/* <Icon as={CheckCircleIcon} w={16} h={16} color="green.500" /> */}
              <Badge colorScheme="green" p={3} borderRadius="lg" fontSize="md" bg="green.100" color="green.700" animation="fadeIn 0.5s ease-in">
                Transazione Completata
              </Badge>
              <Heading as="h1" size="xl" color="gray.700" textAlign="center" fontWeight="bold">
                Grazie per il tuo acquisto!
              </Heading>
            </Flex>

            <Text fontSize="lg" color="gray.600" textAlign="center" maxW="md" lineHeight="tall">
              Ti abbiamo inviato un link alla mail con le credenziali per accedere all'app.
            </Text>

            <Text fontSize="sm" color="gray.500" fontStyle="italic" mt={2}>
              Se non trovi la mail, controlla nella posta indesiderata
            </Text>

            {/* App Store Section */}
            <VStack gap={6} mt={8} >
              <Text fontSize="lg" fontWeight="medium" color="gray.700" textAlign="center">
                Scarica l'app di Kiddocare
              </Text>

              <HStack gap={6} justify="center">
                <Link href="https://apps.apple.com/app/kiddocare" _hover={{ transform: 'translateY(-4px)', transition: 'all 0.2s' }}>
                  <Flex align="center" bg="black" color="white" px={7} py={3.5} borderRadius="xl" gap={3} boxShadow="lg" _hover={{ boxShadow: 'xl', bg: 'gray.900' }}                  >
                    <Flex align="center" justify="center" bg="#147EFB" p={1.5} borderRadius="lg">
                      <GrAppleAppStore size={26} />
                    </Flex>                    <VStack gap={0} align="start">
                      <Text fontSize="sm" opacity={0.9} fontWeight="medium">Download on the</Text>
                      <Text fontSize="lg" fontWeight="extrabold">App Store</Text>
                    </VStack>
                  </Flex>
                </Link>
                <Link href="https://play.google.com/store/apps/kiddocare" _hover={{ transform: 'translateY(-4px)', transition: 'all 0.2s' }}>
                  <Flex align="center" bg="black" color="white" px={7} py={3.5} borderRadius="xl" gap={3} boxShadow="lg" _hover={{ boxShadow: 'xl', bg: 'gray.900' }}>
                    <Flex align="center" justify="center" bg="#01875F" p={1.5} borderRadius="lg">
                      <FaGooglePlay size={26} />
                    </Flex>
                    <VStack gap={0} align="start">
                      <Text fontSize="sm" opacity={0.9} fontWeight="medium">GET IT ON</Text>
                      <Text fontSize="lg" fontWeight="extrabold">Play Store</Text>
                    </VStack>
                  </Flex>
                </Link>
              </HStack>
            </VStack>

            {/* Support Contact */}
            <Text fontSize="md" textAlign="center" color="gray.600">
              Per supporto, contattaci a:{' '}
              <Link href="mailto:info@kiddocare.com" color="blue.600" fontWeight="medium" _hover={{ color: 'blue.700', textDecoration: 'underline' }}              >
                info@kiddocare.com
              </Link>
            </Text>
          </VStack>
        </Box>
      </Container>
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(-10px); }
            to { opacity: 1; transform: translateY(0); }
          }
        `}
      </style>
    </Box>


  );
};